 
import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom'
import {DConnect, SimpleAlert} from '../components/Config';

function CategoryNav2(props) {
  const [category, setCategory] = useState([]);
  const [area, setArea] = useState(props.area);
    if (props.area !== area) setArea(props.area);

    useEffect(() => {
        GetCategory()
    }, [props.area]);

  const GetCategory = () => {
    DConnect()
      .get(`endpoints.php?tbl=0`)
      .then((res) => {
        let cat = res.data;
        setCategory(cat.data)
        
      })
      .catch((error) => SimpleAlert({message: `Categories: ${error.message}`}));
  };

  return (
    <div className="col-lg-3 d-none d-lg-block">
    <a className="btn shadow-none d-flex align-items-center justify-content-between bg-primary text-white w-100" data-toggle="collapse" href="#navbar-vertical" style={{height: '65px', marginTop: '-1px', padding: '0 30px'}}>
        <h6 className="m-0">Categories</h6>
        <i className="fa fa-angle-down text-dark"></i>
    </a>
    <nav className="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 border border-top-0 border-bottom-0 bg-light" id="navbar-vertical" style={{width: 'calc(100% - 30px)', zIndex: 1}}>
        <div className="navbar-nav w-100 overflow-auto" style={{height: '410px'}}>
            {/* <div className="nav-item dropdown">
                <a href="#" className="nav-link" data-toggle="dropdown">Baby &amp; Mother Care <i className="fa fa-angle-down float-right mt-1"></i></a>
                <div className="dropdown-menu position-absolute bg-secondary border-0 rounded-0 w-100 m-0">
                    <a href="" className="dropdown-item text-wrap">Acetaminophen</a>
                    <a href="" className="dropdown-item text-wrap">Nonsteroidal anti-inflammatory drugs (NSAIDs)</a>
                    <a href="" className="dropdown-item text-wrap">Combination</a>
                </div>
            </div> */}
            {category.map(row =>{ return(<Link  key={row.id} onClick={(e)=>props.handleClick(row.id,e)} to="/category" state={{searchVal:row.id}}  className="nav-item nav-link">{row.name}</Link>)})}
        </div>
    </nav>
</div>
  );
}

export default CategoryNav2;
