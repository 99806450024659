 
import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import ProductDetailsHeader from "./ProductDetailsHeader";
import ProceedToPayment from "./ProceedToPayment";


function Checkout(props) {
  const [cartItems, setCartItems] = useState(props.cartItems);
  const [category, setCategory] = useState(props.category);
  if (props.category !== category) setCategory(props.category);

 React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    setCategory(props.category)
}, [props.category]);

  return (
    <>
      <Navbar area={1} fullName={props.fullName} userDetails={props.userDetails} />
      <ProductDetailsHeader heading={'Checkout'} />
      <ProceedToPayment fullName={props.fullName} userDetails={props.userDetails} category={category} cartManager={props.cartManager} cartItems={cartItems} />
    </>
  );
}

export default Checkout;
