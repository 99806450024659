 
import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom'

function ProductsByCategory(props) {
    const [productInfo, setProductInfo] = useState(props.productInfo);
    const [category, setCategory] = useState(props.category);
    if (props.category !== category) setCategory(props.category);

    useEffect(() => {
        setProductInfo(props.productInfo);

    }, [props.productInfo]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
  }

  return (
    <div className="container-fluid pt-5">
        <div className="row px-xl-5">
            <div className="col-lg-12 col-md-12">
                <div className="row pb-3">
                    <div className="col-12 pb-1">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <form action="">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search by name" />
                                    <div className="input-group-append">
                                        <span className="input-group-text bg-transparent text-primary">
                                            <i className="fa fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </form>
                            <div className="text-center mb-4">
                                <h2 className="section-title px-5">
                                <span className="px-2">{productInfo.length ? productInfo[0].catname: 'No Category'}</span>
                                </h2>
                            </div>
                            <div className="dropdown ml-4">
                                <button className="btn border dropdown-toggle" type="button" id="triggerId" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                            Sort by
                                        </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="triggerId">
                                    <a className="dropdown-item" href="#">Latest</a>
                                    <a className="dropdown-item" href="#">Popularity</a>
                                    <a className="dropdown-item" href="#">Best Rating</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {productInfo.map(prod =>{
                           if(prod.productid > 0){
                            let categoryData = category.filter(cat => prod.categoryid == cat.id)
                            let products = categoryData[0].product.filter(product => prod.productid == product.id)[0]
                        
                        return(<div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                        <div className="card product-item border-0 mb-4">
                            <Link to="/details" onClick={()=> props.updateItem({categoryid: products.categoryid, productid:products.id, count: 0})} className="btn btn-sm text-dark p-0">
                            <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                                <img className="img-fluid w-100" src={products.imageUrl} alt="" />
                            </div>
                            </Link>
                            <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                                <h6 className="text-truncate mb-3">{products.name}</h6>
                                <div className="d-flex justify-content-center">
                                    <h6>{'GHS ' + products.currentPrice}</h6><h6 className="text-muted ml-2"><del>{'GHS ' + products.initialPrice}</del></h6>
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-around bg-light border">
                            <button className="btn" onClick={()=>props.cartManager({categoryid: products.categoryid, productid:products.id, count: 1}, 1)}><i className="fas fa-shopping-cart text-primary mr-1"></i>Add To Cart</button>
                            </div>
                        </div>
                    </div>)}})}
                    
                    <div className="col-12 pb-1">
                        <nav aria-label="Page navigation">
                          <ul className="pagination justify-content-center mb-3">
                            <li className="page-item">
                              <a className="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                              </a>
                            </li>
                            <li className="page-item active"><a className="page-link" href="#">1</a></li>
                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item">
                              <a className="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>

  );
}

export default ProductsByCategory;
