
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment';
import { usePaystackPayment } from './dist/index.es';
import { Alert, PostOptions, DConnect, SimpleAlert, setUser, getUser } from '../components/Config';


function ProceedToPayment(props) {
    const [cartItems, setCartItems] = useState(props.cartItems);
    const [subTotal, setSubTotal] = useState(0);
    const [category, setCategory] = useState(props.category);
    const [formItems, setFormItems] = useState({ id: 0, firstname: '', lastname: '', email: '', mobile: '', address: '', country: '', createdon: new Date() });
    const [LoginFormItems, setLoginFormItems] = useState({ email: '', password: '' });
    const [formVisibility, setFormVisibility] = useState(getUser() == null ? true : false);
    const [isLoader, setLoader] = useState(false);
    const [regFormItems, setRegFormItems] = useState({
        id: 0, firstname: '', lastname: '',
        purchasetype: '', org_name: '', org_address: '', contact_person: '', contact_mobile: '',
        business_cert: '', username: '', email: '', password: '', pwdconfirm: '', extension: '', createdon: moment().format('YYYY-MM-DD HH:mm:ss')
    });
    const [isVisible, setVisibility] = useState(true);
    if (props.category !== category) setCategory(props.category);

    const config = {
        reference: (new Date()).getTime().toString(),
        email: getUser() == null ? formItems.email : getUser().email,
        amount: (parseInt(subTotal) + 10) * 100,
        publicKey: 'pk_test_458a3105fee75c85295f4bdf6d968ef1af3aa169',
        firstname: getUser() == null ? formItems.firstname : getUser().firstname,
        lastname: getUser() == null ? formItems.lastname : getUser().laststname,
        currency: "GHS",
        //channels:['mobile_money','card','bank_transfer','mobile_money'],
        /*split: { //if you want to use transaction split
            "type": "percentage",
            "bearer_type": "all",
            "subaccounts": [
                {
                    "subaccount": "ACCT_mtl3xzwjfhcldkw",
                    "share": 30
                },
                {
                    "subaccount": "ACCT_y19ht107y44o294",
                    "share": 20
                }
            ]
        }*/
    };

    const PaystackHookExample = () => {
        //config['reference'] = (new Date()).getTime().toString();
        const initializePayment = usePaystackPayment(config);
        return (
            <div>
                <button className="btn btn-lg btn-block btn-primary font-weight-bold my-3 py-3" onClick={() => {
                    initializePayment(onSuccess, onClose)
                }}>
                    Place Order</button>

            </div>
        );
    };

    const onSuccess = (data) => {
        let { reference } = data;
        let item = cartItems[0] !== undefined ? cartItems[0] : {};
        DConnect().get(`endpoints.php?email=${formItems.email}&cat=${item.categoryid}&prod=${item.productid}`).then((res) => {

            let paymentObj = {
                id: 0, 'paidby': config.email, 'reference': reference,
                'cartitemsid': res.data.data.cartitemsid, 'amount': config.amount / 100, 'createdon': moment()
            }
            axios(PostOptions("POST", paymentObj, "endpoints.php?name=pymt"))
                .then((res) => {
                    window.location.replace("/");
                })
        })
    };

    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log('closed')
    }

    const handleChange = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        setFormItems(prevState => ({ ...prevState, [name]: value }));
    };

    const handleLoginChange = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        setLoginFormItems(prevState => ({ ...prevState, [name]: value }));
    };

    const handleRegChange = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        setRegFormItems(prevState => ({ ...prevState, [name]: value }));
    };

    const getExtension = (filename) => {
        return filename.split('.').pop()
    }

    const onFileChange = (e) => {
        let files = e.target.files;
        const { name, value } = e.target;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
        let extension = getExtension(files[0].name);
        fileReader.onload = (event) => {
            setRegFormItems(prevState => ({ ...prevState, [name]: event.target.result }));
        }
        setRegFormItems(prevState => ({ ...prevState, ['extension']: extension }));
    }

    const submitRegForm = (e) => {
        e.preventDefault();
        setLoader(true);
        if (regFormItems.password == regFormItems.pwdconfirm) {
            axios(PostOptions("POST", regFormItems, "endpoints.php?name=confirm"))
                .then((res) => {
                    let isNotExist = res.data
                    if (isNotExist.data) {
                        axios(PostOptions("POST", regFormItems, "endpoints.php?name=register"))
                            .then((res) => {
                                axios(PostOptions("POST", { email: regFormItems.email, password: regFormItems.password }, "endpoints.php?name=validate"))
                                    .then((res) => {
                                        let user = res.data
                                        if (user.data.id != undefined) {
                                            setUser(user.data);
                                            setFormVisibility(false);
                                            SimpleAlert({ message: "Registration Successful..." });
                                            props.userDetails();
                                            setLoader(false);
                                        }
                                    })
                            }).catch((error) =>
                                SimpleAlert({ message: `Error: ${error.message}` })
                            );
                    } else {
                        SimpleAlert({ message: "Email address already exist in our records..." });
                        setLoader(false);
                    }
                })
        } else {
            SimpleAlert({ message: "Please ensure that you have confirmed your password correctly before you submit..." });
            setLoader(false);
        }
    }

    const submitForm = (e) => {
        e.preventDefault();
        axios(PostOptions("POST", formItems, "endpoints.php?name=billing"))
            .then((res) => {
                const { email } = formItems;
                axios(PostOptions("POST", { email: email, data: cartItems }, "endpoints.php?name=cart"))
                    .then((res) => { });
            })
            .catch((error) =>
                SimpleAlert({ message: `Error: ${error.message}` })
            );
        setVisibility(false)
    }

    const submitLoginForm = (e) => {
        e.preventDefault();
        setLoader(true);
        axios(PostOptions("POST", LoginFormItems, "endpoints.php?name=validate"))
            .then((res) => {
                let user = res.data
                if (user.data.id != undefined) {
                    setUser(user.data);
                    setFormVisibility(false)
                    SimpleAlert({ message: "Login Successful..." });
                    props.userDetails();
                    setLoader(false);
                } else {
                    SimpleAlert({ message: "Incorrect username/password. Please try again..." });
                    setLoader(false);
                }
            })
    };

    useEffect(() => {
        setCartItems(props.cartItems)
        getSubTotal();
        if (props.cartItems.length === 0) {
            window.location.replace("/");
        }
    }, [props.cartItems]);

    const getSubTotal = () => {
        let total = 0
        cartItems.map(row => {
            let categoryData = category.filter(cat => cat.id === row.categoryid)
            let product = categoryData[0].product.filter(rw => rw.id === row.productid)[0];
            total += product.currentPrice * row.count
        })
        setSubTotal(total);
    }

    const loading = () => <div id="loader" />

    if (isLoader) {
        return loading();
    } else {
        return (
            <div class="container-fluid pt-5">
                <div class="row px-xl-5">
                    <div class="col-lg-8">
                        {formVisibility && <div class="row">
                            <div className="col-10">
                                <div className="nav nav-tabs border-secondary mb-4">
                                    <a className="nav-item nav-link active" data-toggle="tab" href="#tab-pane-1">Login</a>
                                    <a className="nav-item nav-link" data-toggle="tab" href="#tab-pane-2">Register</a>
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="tab-pane-1">
                                        <h3 className="text-center mb-4">Have an account?</h3>
                                        <form action="#" className="login-form" onSubmit={submitLoginForm}>
                                            <div className="form-group">
                                                <input type="email" className="form-control rounded-left" id="email" name="email" placeholder="Email Address" onChange={handleLoginChange} autoComplete="off" required />
                                            </div>
                                            <div className="form-group d-flex">
                                                <input type="password" className="form-control rounded-left" id="password" name="password" placeholder="Password" onChange={handleLoginChange} autoComplete="new-password" required />
                                            </div>
                                            <div className="form-group d-md-flex">
                                                <div className="w-50" />
                                                <div className="w-50 text-md-right">
                                                    <a href="#">Forgot Password</a>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary rounded submit p-3 px-5">Login</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane fade" id="tab-pane-2">
                                        <h3 className="text-center mb-4">Registration Form</h3>
                                        <form action="#" className="login-form" onSubmit={submitRegForm}>
                                            <div className="form-group">
                                                <input type="text" className="form-control rounded-left" id="firstname" name="firstname" placeholder="First Name" onChange={handleRegChange} required />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control rounded-left" id="lastname" name="lastname" placeholder="Last Name" onChange={handleRegChange} required />
                                            </div>
                                            <div className="form-group">
                                                <input type="email" className="form-control rounded-left" id="email" name="email" placeholder="Email Address" onChange={handleRegChange} required autoComplete="off" />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="form-control rounded-left" id="contact_mobile" name="contact_mobile" placeholder="Mobile" onChange={handleRegChange} required />
                                            </div>
                                            <div className="form-group d-flex">
                                                <input type="password" className="form-control rounded-left" id="password" name="password" placeholder="Password" onChange={handleRegChange} required autoComplete="new-password" />
                                            </div>
                                            <div className="form-group d-flex">
                                                <input type="password" className="form-control rounded-left" id="pwdconfirm" name="pwdconfirm" placeholder="Confirm Password" onChange={handleRegChange} required autoComplete="new-password" />
                                            </div>
                                            <div className="form-group input-group">
                                                <div className="input-group-append">
                                                    <label>&nbsp;&nbsp;Purchase Type&nbsp;&nbsp;</label>
                                                </div>
                                                <select class="custom-select" id="purchasetype" name="purchasetype" onChange={handleRegChange} required>
                                                    <option value=""></option>
                                                    <option value="1">Puchase by Pharmacy</option>
                                                    <option value="2">Puchase by Hospital/Clinic</option>
                                                    <option value="3">Puchase by Individual</option>
                                                </select>
                                            </div>
                                            {[1, 2].includes(parseInt(regFormItems.purchasetype)) && <><div className="form-group">
                                                <input type="text" className="form-control rounded-left" id="org_name" name="org_name" placeholder={regFormItems.purchasetype == 1 ? "Name of Pharmacy" : "Name of Hospital/Clinic"} onChange={handleRegChange} required />
                                            </div>
                                                <div className="form-group">
                                                    <textarea type="text" className="form-control rounded-left" id="org_address" name="org_address" placeholder={regFormItems.purchasetype == 1 ? "Address of Pharmacy" : "Address of Hospital/Clinic"} onChange={handleRegChange} required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control rounded-left" id="contact_person" name="contact_person" placeholder="Name of Contact Person" onChange={handleRegChange} required />
                                                </div>

                                                <div className="form-group">

                                                    <label>Upload Business Certificate</label>

                                                    <input type="file" className="form-control rounded-left" id="business_cert" name="business_cert" placeholder="Upload Business Certificate" onChange={onFileChange} required />
                                                </div>
                                            </>}

                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary rounded submit p-3 px-5">Register</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {!formVisibility && <div class="mb-4">
                            <h4 class="font-weight-semi-bold mb-4">Billing Address</h4>
                            <form onSubmit={submitForm}>
                                <div class="row">
                                    <div class="col-md-6 form-group">
                                        <label>First Name</label>
                                        <input class="form-control" type="text" name="firstname" value={formItems.firstname} placeholder="John" onChange={handleChange} required />
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label>Last Name</label>
                                        <input class="form-control" type="text" name="lastname" value={formItems.lastname} placeholder="Doe" onChange={handleChange} required />
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label>E-mail</label>
                                        <input class="form-control" type="email" name="email" value={formItems.email} placeholder="example@email.com" onChange={handleChange} required />
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label>Mobile No</label>
                                        <input class="form-control" type="text" name="mobile" value={formItems.mobile} placeholder="0234456789" onChange={handleChange} required />
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label>Residential Address</label>
                                        <textarea class="form-control" type="text" name="address" value={formItems.address} placeholder="123 Street" onChange={handleChange} required>
                                        </textarea>
                                    </div>

                                    <div class="col-md-6 form-group">
                                        <label>Country</label>
                                        <input class="form-control" type="text" name="country" value={formItems.country} onChange={handleChange} placeholder="Ghana" required />
                                    </div>

                                    {/* <div class="col-md-12 form-group">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="newaccount" />
                                <label class="custom-control-label" for="newaccount">Create an account</label>
                            </div>
                        </div>
                        <div class="col-md-12 form-group">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="shipto" />
                                <label class="custom-control-label" for="shipto"  data-toggle="collapse" data-target="#shipping-address">Ship to different address</label>
                            </div>
                        </div> */}
                                </div>
                                {isVisible && <button type="submit" className="btn btn-lg btn-block btn-primary font-weight-bold my-3 py-3">Click to Place Order</button>}
                            </form>
                        </div>}
                        {/* <div class="collapse mb-4" id="shipping-address">
                    <h4 class="font-weight-semi-bold mb-4">Shipping Address</h4>
                    <div class="row">
                      <div class="col-md-6 form-group">
                            <label>First Name</label>
                            <input class="form-control" type="text" placeholder="John" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Last Name</label>
                            <input class="form-control" type="text" placeholder="Doe" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label>E-mail</label>
                            <input class="form-control" type="text" placeholder="example@email.com" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Mobile No</label>
                            <input class="form-control" type="text" placeholder="0234 456 789" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Residential Address</label>
                            <textarea class="form-control" type="text" placeholder="123 Street">
                            </textarea>
                        </div>
                        
                        <div class="col-md-6 form-group">
                            <label>City</label>
                            <input class="form-control" type="text" placeholder="New York" />
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Country</label>
                            <select class="custom-select">
                                <option selected>Ghana</option>
                            </select>
                        </div>
                        <div class="col-md-6 form-group">
                            <label>Region</label>
                            <select class="custom-select">
                                <option selected>Greater Accra</option>
                            </select>
                        </div>
                    </div>
                </div> */}
                    </div>
                    <div class="col-lg-4">
                        <div class="card border-secondary mb-5">
                            <div class="card-header bg-secondary border-0">
                                <h4 class="font-weight-semi-bold m-0">Order Total</h4>
                            </div>
                            <div class="card-body">
                                <h5 class="font-weight-medium mb-3">Products</h5>
                                {cartItems.map((row, index) => {
                                    let categoryData = category.filter(cat => cat.id === row.categoryid)
                                    let product = categoryData[0].product.filter(rw => rw.id === row.productid)[0];
                                    return (
                                        <div class="d-flex justify-content-between" key={index}>
                                            <p>{product.name}</p>
                                            <p>{'GHS ' + (product.currentPrice * row.count)}</p>
                                        </div>)
                                })}

                                <hr class="mt-0" />
                                <div class="d-flex justify-content-between mb-3 pt-1">
                                    <h6 class="font-weight-medium">Subtotal</h6>
                                    <h6 class="font-weight-medium">GHS {subTotal}</h6>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <h6 class="font-weight-medium">Shipping</h6>
                                    <h6 class="font-weight-medium">GHS 10</h6>
                                </div>
                            </div>
                            <div class="card-footer border-secondary bg-transparent">
                                <div class="d-flex justify-content-between mt-2">
                                    <h5 class="font-weight-bold">Total</h5>
                                    <h5 class="font-weight-bold">GHS {subTotal + 10}</h5>
                                </div>
                            </div>
                        </div>
                        <div class="card border-secondary mb-5">
                            <div class="card-header bg-secondary border-0">
                                <h4 class="font-weight-semi-bold m-0">Payment</h4>
                            </div>
                            <div class="card-body">
                            </div>
                            <div class="card-footer border-secondary bg-transparent">
                                {!isVisible && <PaystackHookExample />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProceedToPayment;
