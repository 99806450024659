import "./App.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./components/portal.js";
import Content from "./views/Content";
import Details from "./views/Details";
import CategoryDetails from "./views/CategoryDetails";
import {Alert, DConnect, getUser} from './components/Config';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Cart from "./views/Cart";
import Checkout from "./views/Checkout";
import Contact from "./views/Contact";
import Topbar from "./views/Topbar";
import Footer from "./views/Footer";
import Login from "./views/Login";
import Register from "./views/Register";

function App(){
  const [cartItems, setCartItems] = useState([]);
  const [category, setCategory] = useState([]);
  const [item, setItem] = useState({});
  const [area, setArea] = useState(0);
  const [key, setKey] = useState(1);
  const [fullName, setFullName] = useState(null);
  const [isLoader, setLoader] = useState(false);
  

  useEffect(() => {
    GetCategory()
    GetCartItems()
}, [area]);

  const cartManager = (newItem, action) => {
    let items = cartItems
    let numberArray =[0,1]
    let isExist = false
    Alert({message:`An Item has been added to your shopping cart.`});
    items.map(row =>{
      if(row.categoryid == newItem.categoryid && row.productid == newItem.productid){
        row['count'] = row.count + numberArray[action]
        isExist = true
      }
    })
    
    if(isExist){
      setKey((new Date()).getTime())
      setCartItems(prevState => ( items ));
    }else{
      if(newItem.count == 0) newItem['count'] = 1;
      setKey((new Date()).getTime())
      setCartItems(prevState => ([ ...prevState, newItem ]));
    }    
 }

 const updateItem = (newItem) => {
  setItem(newItem)
 }

 const userDetails = () => {
  setFullName(getUser() != null ? getUser().firstname + ' '+ getUser().lastname : getUser()); 
 }

 const GetCartItems = async () => {
  let email = getUser() != null ? getUser().data.email : getUser();
  if(email != null){
  setLoader(true);
  await DConnect()
 .get(`endpoints.php?cart=1&tbl=2&tblid=${email}`)
 .then((res) => {
   let cart =  res.data;
   setKey((new Date()).getTime())
   setCartItems(cart.data)
   setLoader(false);
 })
 .catch((error) => alert(`Cart: ${error.message}`));
}
 };

 

const GetCategory = async () => {
  setLoader(true);
  await
  axios.get(`https://backend.akspharmacygh.com/endpoints.php?cat=1`)
 .then((res) => {
   let cat =  res.data;
   setCategory(cat.data)
   setLoader(false);
 })
 .catch((error) => alert(`Categories: ${error.message}`));
 };

 const handleClick = (areaData, e) =>{
  setArea(areaData)
}


 const quantityManager = (type, newItem) => {
  let items = cartItems
  let numberArray =[-1,1]
  items.map(row =>{
    if(row.categoryid == newItem.categoryid && row.productid == newItem.productid){
      if(row.count + numberArray[type] > 0){
      row['count'] = parseInt(row.count) + parseInt(numberArray[type])
      }
    }
  })
  setKey((new Date()).getTime())
  setCartItems(prevState => ([ ...prevState ]));
     
}

const deleteItem = (newItem) => {
  let items = cartItems
  const indexOfObject = items.findIndex(row => {
    return row.categoryid == newItem.categoryid && row.productid == newItem.productid;
  });
  items.splice(indexOfObject, 1);
  setKey((new Date()).getTime())
  setCartItems(prevState => ([ ...prevState ]));
     
}
const loading = () => <div id="loader" />

if (isLoader) {
 return loading();
} else {
 return( 
 <>
 <Topbar key= {key} area={area} fullName={fullName} userDetails={userDetails} handleClick={handleClick} cartItems={cartItems} />
        <Routes>
        
          <Route exact path="/" element={<Content item={item} fullName={fullName} userDetails={userDetails} updateItem={updateItem} area={area} handleClick={handleClick} cartItems={cartItems} category={category} cartManager={cartManager} />} />
          <Route path="/details"  element={<Details item={item} fullName={fullName}  userDetails={userDetails} updateItem={updateItem} area={area} handleClick={handleClick} cartItems={cartItems} category={category} cartManager={cartManager} quantityManager={quantityManager} />} />
          <Route path="/category" element={<CategoryDetails fullName={fullName}  item={item} userDetails={userDetails} updateItem={updateItem} area={area} handleClick={handleClick} cartItems={cartItems} category={category} cartManager={cartManager} quantityManager={quantityManager} />} />
          <Route path="/login" element={<Login item={item} fullName={fullName}  userDetails={userDetails} updateItem={updateItem} area={area} handleClick={handleClick} cartItems={cartItems} category={category} cartManager={cartManager} quantityManager={quantityManager} />} />
          <Route path="/register" element={<Register item={item} fullName={fullName} userDetails={userDetails} updateItem={updateItem} area={area} handleClick={handleClick} cartItems={cartItems} category={category} cartManager={cartManager} quantityManager={quantityManager} />} />
          <Route path="/cart" element={<Cart cartItems={cartItems} fullName={fullName} userDetails={userDetails} category={category} quantityManager={quantityManager} deleteItem={deleteItem} />} />
          <Route path="/checkout" element={<Checkout cartItems={cartItems} fullName={fullName} userDetails={userDetails} category={category} cartManager={cartManager} />} />
          <Route path="/contact" element={<Contact fullName={fullName} userDetails={userDetails} />} />
          <Route exact path="/" element={<Navigate to="/" />} />
        </Routes>
  <Footer />     
</>
 )
 }
}
export default App;
