 
import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom'
import axios from "axios";
import {getUser, PostOptions} from '../components/Config';


function Topbar(props) {
    const [cartItems, setCartItems] = useState(props.cartItems);
    const [searchText, setSearchText] = useState({searchValue: ""});
    if (props.cartItems !== cartItems) setCartItems(props.cartItems);

    useEffect(() => {
        setCartItems(props.cartItems)
        saveCartItems(props.cartItems)
    }, [props.cartItems]);

    

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setSearchText({ [name]:value });
      }

      const saveCartItems = async (items) => {
        let emailinfo = getUser() != null ? getUser().data.email : getUser();
        if(emailinfo != null){
        axios(PostOptions("POST", {email: emailinfo, data:items}, "endpoints.php?name=cart"))
        .then((res) => {
            
        });
        }
       }

  return (
  <div className="container-fluid">
        <div className="row bg-secondary py-2 px-xl-5">
            <div className="col-lg-6 d-none d-lg-block" />
            <div className="col-lg-6 text-center text-lg-right">
                <div className="d-inline-flex align-items-center">
                    <a className="text-dark px-2" href="">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a className="text-dark px-2" href="">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a className="text-dark px-2" href="">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a className="text-dark px-2" href="">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a className="text-dark pl-2" href="">
                        <i className="fab fa-youtube"></i>
                    </a>
                </div>
            </div>
        </div>
        <div className="row align-items-center py-3 px-xl-5">
            <div className="col-lg-3 d-none d-lg-block">
                <Link to="/" className="text-decoration-none">
                    <h2 className="m-0 display-5 font-weight-semi-bold"><span className="text-primary font-weight-bold border px-3 mr-1">AKS</span><span className="text-muted">Pharmacy</span></h2>
                </Link>
            </div>
            <div className="col-lg-6 col-6 text-left">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search for products" name="searchValue" onChange={handleChange} />
                        <Link onClick={(e)=>props.handleClick(searchText.searchValue, e)} to="/category" state= {{ searchVal: searchText.searchValue }}  className="input-group-append">
                            <span className="input-group-text bg-transparent text-primary">
                                <i className="fa fa-search"></i>
                            </span>
                        </Link>
                    </div>
            </div>
            <div className="col-lg-3 col-6 text-right">
                <a href="" className="btn border">
                    <i className="fas fa-heart text-primary"></i>
                    <span className="badge">0</span>
                </a>
                <Link to="/cart" className="btn border">
                    <i className="fas fa-shopping-cart text-primary"></i>
                    <span className="badge">{cartItems.length}</span>
                </Link>
            </div>
        </div>
    </div>

  );
}

export default Topbar;
