 
import React, { useEffect, useState } from "react";
import {DoLogin} from '../components/Config';

function Login(props) {
    const [formItems, setformItems] = useState({ email: '', password: '' });
    const [cartItems, setCartItems] = useState(props.cartItems);
	const [isLoader, setLoader] = useState(false);

    const handleChange = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        setformItems(prevState => ({ ...prevState, [name]: value }));
    };

    const submitForm = (e) => {
        e.preventDefault();
        //console.log(formItems);
        DoLogin(formItems, cartItems);
    };
  

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])  

  const loading = () => <div id="loader" />

  if (isLoader) {
	return loading();
   } else {
  return (
    <>
      <section className="ftco-section">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-6 col-lg-5">
					<div className="login-wrap p-4 p-md-5">
		      	<div className="icon d-flex align-items-center justify-content-center">
		      		<span className="fa fa-user-o"></span>
		      	</div>
		      	<h3 className="text-center mb-4">Have an account?</h3>
				<form action="#" className="login-form" onSubmit={submitForm}>
		      		<div className="form-group">
		      			<input type="email" className="form-control rounded-left" id="email" name="email"  placeholder="Email Address" onChange={handleChange} autoComplete="off" required />
		      		</div>
	            <div className="form-group d-flex">
	              <input type="password" className="form-control rounded-left" id="password" name="password" placeholder="Password" onChange={handleChange} autoComplete="new-password" required />
	            </div>
	            <div className="form-group d-md-flex">
	            	<div className="w-50" />
                    <div className="w-50 text-md-right">
                        <a href="#">Forgot Password</a>
                    </div>
	            </div>
	            <div className="form-group">
	            	<button type="submit" className="btn btn-primary rounded submit p-3 px-5">Login</button>
	            </div>
	          </form>
	        </div>
				</div>
			</div>
		</div>
	</section>
    </>
  );
}
}

export default Login;
