
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment';
import { Alert, PostOptions, SimpleAlert } from '../components/Config';


function Register(props) {
	const [formItems, setformItems] = useState({
		id: 0, firstname: '', lastname: '',
		purchasetype: '', org_name: '', org_address: '', contact_person: '', contact_mobile: '',
		business_cert: '', username: '', email: '', password: '', pwdconfirm: '', extension: '', createdon: moment().format('YYYY-MM-DD HH:mm:ss')
	});
	const [cartItems, setCartItems] = useState(props.cartItems);
	const [isLoader, setLoader] = useState(false);

	const handleChange = (e) => {
		e.preventDefault();

		const { name, value } = e.target;

		setformItems(prevState => ({ ...prevState, [name]: value }));
	};

	const getExtension = (filename) => {
		return filename.split('.').pop()
	}

	const onFileChange = (e) => {
		let files = e.target.files;
		const { name, value } = e.target;
		let fileReader = new FileReader();
		fileReader.readAsDataURL(files[0]);
		let extension = getExtension(files[0].name);
		fileReader.onload = (event) => {
			setformItems(prevState => ({ ...prevState, [name]: event.target.result }));
		}
		setformItems(prevState => ({ ...prevState, ['extension']: extension }));
	}

	const submitForm = (e) => {
		e.preventDefault();
		setLoader(true);
		if (formItems.password == formItems.pwdconfirm) {
			axios(PostOptions("POST", formItems, "endpoints.php?name=confirm"))
				.then((res) => {
					let isNotExist = res.data
					if (isNotExist.data) {
						axios(PostOptions("POST", formItems, "endpoints.php?name=register"))
							.then((res) => {
								SimpleAlert({ message: `Registration Successful...` })
								setLoader(false);
							}).catch((error) =>{
								SimpleAlert({ message: `Error: ${error.message}` });
								setLoader(false);
					});
					} else {
						SimpleAlert({ message: "Email address already exist in our records..." });
						setLoader(false);
					}
				})
		} else {
			Alert({ title: "", message: "Please ensure that you have confirmed your password correctly before you submit...", buttons: [{ label: "Ok" }] });
		}
	}


	React.useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [])

	const loading = () => <div id="loader" />

	if (isLoader) {
		return loading();
	} else {
		return (
			<>
				<section className="ftco-section">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-md-6 col-lg-5">
								<div className="login-wrap p-4 p-md-5">
									<div className="icon d-flex align-items-center justify-content-center">
										<span className="fa fa-user-o"></span>
									</div>
									<h3 className="text-center mb-4">Registration Form</h3>
									<form action="#" className="login-form" onSubmit={submitForm}>
										<div className="form-group">
											<input type="text" className="form-control rounded-left" id="firstname" name="firstname" placeholder="First Name" onChange={handleChange} required />
										</div>
										<div className="form-group">
											<input type="text" className="form-control rounded-left" id="lastname" name="lastname" placeholder="Last Name" onChange={handleChange} required />
										</div>
										<div className="form-group">
											<input type="email" className="form-control rounded-left" id="email" name="email" placeholder="Email Address" onChange={handleChange} required autoComplete="off" />
										</div>
										<div className="form-group">
											<input type="text" className="form-control rounded-left" id="contact_mobile" name="contact_mobile" placeholder="Mobile" onChange={handleChange} required />
										</div>
										<div className="form-group d-flex">
											<input type="password" className="form-control rounded-left" id="password" name="password" placeholder="Password" onChange={handleChange} required autoComplete="new-password" />
										</div>
										<div className="form-group d-flex">
											<input type="password" className="form-control rounded-left" id="pwdconfirm" name="pwdconfirm" placeholder="Confirm Password" onChange={handleChange} required autoComplete="new-password" />
										</div>
										<div className="form-group input-group">
											<div className="input-group-append">
												<label>&nbsp;&nbsp;Purchase Type&nbsp;&nbsp;</label>
											</div>
											<select class="custom-select" id="purchasetype" name="purchasetype" onChange={handleChange} required>
												<option value=""></option>
												<option value="1">Puchase by Pharmacy</option>
												<option value="2">Puchase by Hospital/Clinic</option>
												<option value="3">Puchase by Individual</option>
											</select>
										</div>
										{[1, 2].includes(parseInt(formItems.purchasetype)) && <><div className="form-group">
											<input type="text" className="form-control rounded-left" id="org_name" name="org_name" placeholder={formItems.purchasetype == 1 ? "Name of Pharmacy" : "Name of Hospital/Clinic"} onChange={handleChange} required />
										</div>
											<div className="form-group">
												<textarea type="text" className="form-control rounded-left" id="org_address" name="org_address" placeholder={formItems.purchasetype == 1 ? "Address of Pharmacy" : "Address of Hospital/Clinic"} onChange={handleChange} required />
											</div>
											<div className="form-group">
												<input type="text" className="form-control rounded-left" id="contact_person" name="contact_person" placeholder="Name of Contact Person" onChange={handleChange} required />
											</div>

											<div className="form-group">

												<label>Upload Business Certificate</label>

												<input type="file" className="form-control rounded-left" id="business_cert" name="business_cert" placeholder="Upload Business Certificate" onChange={onFileChange} required />
											</div>
										</>}

										<div className="form-group">
											<button type="submit" className="btn btn-primary rounded submit p-3 px-5">Register</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default Register;
