 
import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import ProductDetailsHeader from "./ProductDetailsHeader";
import ShoppingCart from "./ShoppingCart";


function Cart(props) {
  const [cartItems, setCartItems] = useState(props.cartItems);
  const [category, setCategory] = useState(props.category);
  if (props.category !== category) setCategory(props.category);

  useEffect(() => {
    setCartItems(props.cartItems)
}, [props.cartItems]);

 React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Navbar area={1} fullName={props.fullName} userDetails={props.userDetails}/>
      <ProductDetailsHeader heading={'Shopping Cart'} />
      <ShoppingCart quantityManager={props.quantityManager}  category={category} cartItems={cartItems} deleteItem={props.deleteItem} />
    </>
  );
}

export default Cart;
