import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom'

function ProductList(props) {
  const [cartItems, setCartItems] = useState(props.cartItems);
  const [categoryList, setCategoryList] = useState(props.category);
  if (props.category !== categoryList) setCategoryList(props.category);
  const [item, setItem] = useState(props.item);
  if (props.item !== item) setItem(props.item);

  return (
   <>
    {categoryList.map((category, idx)=>{
        if(category.id == (item.categoryid != undefined? item.categoryid: category.id)){
        let cat = category;
        let container = [];
        let lowerBound = 0;
        let upperBound = 0;
        let count =  Math.floor(cat.product.length / 4);
        let rem = cat.product.length % 4;
        if(rem > 0){
            count += 1;
        }
        for(let i = 1; i <= count; i++){
            container.push(i);
        }
        
  return(
    <>
    {cat.product.length > 0 && <div className="container-fluid py-5">
      <div className="text-center mb-4">
        <h2 className="section-title px-5">
          <span className="px-2">{cat.name}</span>
        </h2>
      </div>
      <div className="row px-xl-5">
        <div className="col">
          <div
            id={cat.name.substring(0,2) + cat.id}
            className="carousel slide carousel-multi-item"
            data-ride="carousel"
          >
            <div className="controls-top">
              <a
                className="btn-floating mr-3"
                href={'#'+cat.name.substring(0,2) + cat.id}
                data-slide="prev"
              >
                <i className="fa fa-chevron-left"></i>
              </a>
              <a
                className="btn-floating"
                href={'#'+cat.name.substring(0,2) + cat.id}
                data-slide="next"
              >
                <i className="fa fa-chevron-right"></i>
              </a>
            </div>

            <ol className="carousel-indicators">
            {container.map((prod, index)=>(<li key={index}
                data-target={'#'+cat.name.substring(0,2) + cat.id}
                data-slide-to={index.toString()}
                className={index == 0 ? "active":""}
              ></li>))}
            </ol>

            <div className="carousel-inner" role="listbox">
              {container.map((cnt)=>{
                  upperBound = cnt * 4 - 1;
                  lowerBound = upperBound - 3;
            return(
                  <div className={cnt == 1 ? "carousel-item active": "carousel-item"}> 
                   <div className="row">
                {cat.product.map((prod, index)=>{
                    if(index >= lowerBound && index <= upperBound){  
                return(
                  <div className={index != lowerBound ? "col-md-3 clearfix d-none d-md-block":"col-md-3"} key={index}>
                    
                    <div className="card product-item border-0 mb-2">
                    <Link to='/details' onClick={()=> props.updateItem({categoryid: cat.id, productid:prod.id, count: 0})}  className="btn btn-sm text-dark p-0">
                    <div className="card-header product-img position-relative overflow-hidden bg-transparent border p-0">
                       <img className="img-fluid w-100" src={prod.imageUrl} alt="" />
                      </div>
                      </Link>
                      
                      <div className="card-body border-left border-right text-center p-0 pt-4 pb-3">
                        <h6 className="text-truncate mb-3">
                        {prod.name}
                        </h6>
                        <div className="d-flex justify-content-center">
                          <h6>{'GHS ' + prod.currentPrice}</h6>
                          <h6 className="text-muted ml-2">
                            <del>{'GHS ' + prod.initialPrice}</del>
                          </h6>
                        </div>
                      </div>
                      
                      <div className="card-footer d-flex justify-content-lg-center bg-light border">
                          <button className="btn" onClick={()=>props.cartManager({categoryid: cat.id, productid:prod.id, count: 1}, 1)}>
                          <i className="fas fa-shopping-cart text-primary mr-1"></i>
                          Add To Cart
                          </button>
                      </div>
                    </div>
                    
                  </div>
                       )}})}
                   </div>
                   <Link to="/cart" className="fas fa-shopping-cart text-danger mr-1"> View Cart
                   </Link>
                   </div>
                   )})}
                   
            </div>
          </div>
        </div>
      </div>
    </div>}</>)}})}
    </>
  );
}

export default ProductList;
