import axios from "axios";
import CryptoJS from "crypto-js";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)

const DoLogin = (formItems, cartItems) => {
  axios(PostOptions("POST", formItems, "endpoints.php?name=validate"))
    .then((res) => {
      let user = res.data
      //console.log(JSON.stringify(user.data.id));
      if(user.data.id != undefined){
        setUser(user.data);
        SimpleAlert({message: `Login Successful...`})
        const {email}  = formItems
        axios(PostOptions("POST", {email: email, data:cartItems}, "endpoints.php?name=cart"))
      .then((res) => {
        let response = res.data;
        window.location.replace("/");
      });
      }else{
        SimpleAlert({message: "Incorrect username/password. Please try again..."})
      }
     
      //setToken(user.auth_token);

      //GetRole(user.roleid);
      
    })
    .catch((error) =>
      SimpleAlert({message: `Error: ${error.message}`})
    );
};

const GetRole = (roleid) => {
  DConnect()
    .get(`role/${roleid}`)
    .then((res) => {
      let role = DecryptData(res.data);

      //console.log(JSON.parse(role.features));
      setCookie("role", JSON.stringify(role));
      setFeatures(role.features);
      window.location.reload();
    })
    .catch((error) => alert(`Role: ${error.message}`));
};

const logoutAudit = () => {
  let formItems = {
    id: 0,
    audit_type: 3,
    description:
      getUser().firstname + " " + getUser().lastname + " has logged out",
    createdby: getUser().username,
    createdon: new Date(),
  };
  let options = PostOptions("POST", formItems, "audiTrail");
  axios(options).then((res) => {});
};

const Logout = () => {
  //logoutAudit();
  sessionStorage.removeItem("getp_crg_token");
  sessionStorage.removeItem("getp_crg_user");
  sessionStorage.removeItem("getp_crg_features");
  window.location.replace("/");
};

const EncryptData = (data) => {
  let keyHex = CryptoJS.enc.Base64.parse(PrivateKey());
  let encrypted = CryptoJS.AES.encrypt(data, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
};

const DecryptData = (data) => {
  let keyHex = CryptoJS.enc.Base64.parse(PrivateKey());
  let decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(data),
    },
    keyHex,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

const DecryptCAPBUSS = (data) => {
  let keyHex = CryptoJS.enc.Base64.parse("N9Bt8inyra0=");
  let decrypted = CryptoJS.DES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(data),
    },
    keyHex,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

const EncryptCAPBUSS = (data) => {
  let keyHex = CryptoJS.enc.Base64.parse("N9Bt8inyra0=");
  let encrypted = CryptoJS.DES.encrypt(data, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
};

const DecryptText = (data) => {
  let keyHex = CryptoJS.enc.Base64.parse(PrivateKey());
  let decrypted = CryptoJS.DES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(data),
    },
    keyHex,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
};

const MapFields = ({ data, fields }) => {
  let result = {};

  Object.keys(fields).map((key) => {
    result[key] = data[fields[key]];
  });

  return result;
};

const GetCurl = (location) => {
  let loc = location.hash.split("/");
  return loc;
};

const PrivateKey = () => "XSbXJQqF4+CfX7YleAW6gPkXgSKCy8SMqvWRueQOw2k=";
const LoggedIn = () => getUser() !== null;

const setUser = (user) =>
  sessionStorage.setItem("getp_crg_user", JSON.stringify(user));
const getUser = () => JSON.parse(sessionStorage.getItem("getp_crg_user"));

const setToken = (token) => sessionStorage.setItem("getp_crg_token", token);
const getToken = () => sessionStorage.getItem("getp_crg_token");

const getAuthorization = () =>
  getUser() == null
    ? { username: "", token: "" }
    : { username: getUser().username, token: getUser().token };

const setFeatures = (userole) => sessionStorage.setItem("features", userole);
const getFeatures = () => JSON.parse(sessionStorage.getItem("features"));

const setCookie = (name, value) => sessionStorage.setItem(name, value);
const getCookie = (name) => sessionStorage.getItem(name);

const IsAuthenticated = () => LoggedIn();

const DConnect = () => {
  return axios.create({
    baseURL: BaseURL() 
  });
};

const PostOptions = (meth, formBody, area) => {
  // let formData = `data=${encodeURIComponent(
  //   EncryptData(JSON.stringify(formBody))
  // )}`;

  return {
    method: meth,
    url: BaseURL() + area,
    data: formBody,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
  };
};

const TinVerification = (tin_number) => {
  let base = tin_number.substr(1);
  let result = false;

  if (base.length == 10) {
    result = verifyTIN(base);

    if (!result) {
      let unbase = base.slice(0, -1);

      for (var i = 1; i <= unbase.length; i++) {
        result = verifyTIN(unbase + i);

        if (!result) {
          continue;
        }
        break;
      }
    }
  }

  return result;
};

const verifyTIN = (code) => {
  var str = code.toString();
  var strLen = str.length,
    strVal = parseInt(str);
  if (strLen < 8 || strLen > 10 || isNaN(strVal) || strVal == 0) return false;
  while (str.length < 10) str = "0" + str;
  if (str.match(/^(\d)\1+$/g)) return false;
  var checkDigit = parseInt(str.slice(-1)),
    str = str.slice(0, -1);
  for (var sum = 0, i = str.length; i > 0; i--)
    sum += (i + 1) * str.substr(-i, 1);
  var mod = sum % 11;
  return (
    (mod < 2 && mod === checkDigit) || (mod >= 2 && mod + checkDigit === 11)
  );
};

const MakeId = (length) => {
  var result = "";
  var characters = "0123456789";
  var charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return "CRG" + result;
};

const Alert = (props) =>
MySwal.fire({
  title: <strong>Item Added to Cart!</strong>,
  html: <i>{props.message}</i>,
  icon: 'success'
})

const SimpleAlert = (props) =>
MySwal.fire(props.message)
const BaseURL = () => {
  return "https://backend.akspharmacygh.com/";
  //return "http://getp.nbssighcap.com:9030/";
  //return "http://localhost/";
  //return "http://testbknd.motac.systems:9030/";
};

const Config = () => {};

Config.DoLogin = DoLogin;
Config.Logout = Logout;
Config.EncryptData = EncryptData;
Config.DecryptData = DecryptData;
Config.DecryptCAPBUSS = DecryptCAPBUSS;
Config.EncryptCAPBUSS = EncryptCAPBUSS;
Config.DecryptText = DecryptText;
Config.PrivateKey = PrivateKey;
Config.LoggedIn = LoggedIn;
Config.setUser = setUser;
Config.getUser = getUser;
Config.setToken = setToken;
Config.getToken = getToken;
Config.setFeatures = setFeatures;
Config.getFeatures = getFeatures;
Config.IsAuthenticated = IsAuthenticated;
Config.getAuthorization = getAuthorization;
Config.setCookie = setCookie;
Config.getCookie = getCookie;

Config.DConnect = DConnect;
Config.PostOptions = PostOptions;
Config.BaseURL = BaseURL;
Config.MapFields = MapFields;
Config.TinVerification = TinVerification;
Config.MakeId = MakeId;
Config.Alert = Alert;
Config.SimpleAlert = SimpleAlert;
Config.GetCurl = GetCurl;

export default Config;
export {
  DoLogin,
  Logout,
  EncryptData,
  DecryptData,
  DecryptText,
  DecryptCAPBUSS,
  MakeId,
  PrivateKey,
  LoggedIn,
  setUser,
  getUser,
  setToken,
  getToken,
  getAuthorization,
  setFeatures,
  getFeatures,
  DConnect,
  MapFields,
  EncryptCAPBUSS,
  PostOptions,
  BaseURL,
  IsAuthenticated,
  setCookie,
  getCookie,
  TinVerification,
  Alert,
  SimpleAlert,
  GetCurl,
};
