 
import React, { useState } from "react";
import {Link} from 'react-router-dom'
import {IsAuthenticated, getUser, Logout} from '../components/Config';

function MenuNav(props) {
    const [fullName, setFullName] = useState(props.fullName);
    if (props.fullName !== fullName) setFullName(props.fullName);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
  }

  return (
    <nav className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
                <a href="" className="text-decoration-none d-block d-lg-none">
                    <h2 className="m-0 display-5 font-weight-semi-bold"><span className="text-primary font-weight-bold border px-3 mr-1">AKS</span><span className="text-muted">Pharmacy</span></h2>
                </a>
                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                    <div className="navbar-nav mr-auto py-0">
                    <Link to="/" className={props.area == 0 ? "nav-item nav-link active":"nav-item nav-link"}>Home</Link>
                    <Link to="/cart" className={props.area == 1 ? "nav-item nav-link active":"nav-item nav-link"}>Shopping Cart</Link>
                    <Link to="/contact" className={props.area == 2 ? "nav-item nav-link active":"nav-item nav-link"}>Contact</Link>
                    </div>
                    {getUser() == null ? <div className="navbar-nav ml-auto py-0">
                        <Link to="/login" className="nav-item nav-link">Login</Link>
                        <Link to="/register" className="nav-item nav-link">Register</Link>
                    </div>:<div className="navbar-nav ml-auto py-0">
                        <span className="nav-item nav-link">{fullName}</span>
                        <Link to="/" onClick={() => Logout()} className="nav-item nav-link">Logout</Link>
                    </div>}
                </div>
            </nav>
  );
}

export default MenuNav;
