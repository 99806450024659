import $ from 'jquery';

$(function(){
    $('#navbar li ul li').on('click', (ex) => { ex.stopPropagation();});

    $('#navbar').on('click', 'li a', (e) => {
        $('#navbar li ul li').on('click', (ex) => { ex.stopPropagation();});
        
        if($(e.target).closest('li').children('ul').is(':visible')){
            $(e.target).closest('li').children('ul').slideUp();
        }else{
            $('#navbar li.nav-item').removeClass('active');
            $(e.target).closest('li').addClass('active');
            $('.pcoded-submenu').hide(600);
            $(e.target).closest('li').children('ul').slideDown();
        }
    });

    $('ul#settings_menu li').on('click',(e) => {
        $('ul#settings_menu li').removeClass('active');
        $(e.target).addClass('active');
    })
});