 
import React, {  } from "react";
import Navbar from "./Navbar";
import Topbar from "./Topbar";
import Footer from "./Footer";
import ProductDetailsHeader from "./ProductDetailsHeader";
import ContactInfo from "./ContactInfo";


function Contact(props) {

 React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <Navbar area={2} fullName={props.fullName} userDetails={props.userDetails} />
      <ProductDetailsHeader heading={'Contact'} />
      <ContactInfo />
    </>
  );
}

export default Contact;
