 
import React, { useState } from "react";
import {Link} from 'react-router-dom'

function ProductDetailsHeader(props) {

  const handleCardChange = (value, area) => {
     // setCards(prevState => ({ ...prevState, value }));
  }

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
  }

  return (
    <div className="container-fluid mb-5">
        <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: '20px'}}>
  <h3 className="font-weight-semi-bold text-uppercase mb-2">{props.heading}</h3>
            <div className="d-inline-flex">
                <p className="m-0"><Link to="/">Home</Link></p>
                <p className="m-0 px-2">&gt;&gt;</p>
                <p className="m-0">{props.heading}</p>
            </div>
        </div>
    </div>

  );
}

export default ProductDetailsHeader;
