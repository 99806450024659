 
import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import ProductDetailsHeader from "./ProductDetailsHeader";
import ProductDetails from "./ProductDetails";
import ProductList from "./ProductList";


function Details(props) {
  const [cartItems, setCartItems] = useState(props.cartItems);
  const [category, setCategory] = useState(props.category);
  const [area2, setArea] = useState(props.area);
  const [item, setItem] = useState(props.item);
  if (props.area !== area2) setArea(props.area);
  if (props.item !== item) setItem(props.item);
  if (props.category !== category) setCategory(props.category);

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    setCartItems(props.cartItems)
}, [props.cartItems,props.item]);

 



  return (
    <>
      <Navbar area={1} area2={area2} fullName={props.fullName} userDetails={props.userDetails} handleClick={props.handleClick} />
      <ProductDetailsHeader heading={'Product Detail'} />
      <ProductDetails item={props.item} updateItem={props.updateItem} cartManager={props.cartManager} category={category} cartItems={cartItems} quantityManager={props.quantityManager} />
      <ProductList item={props.item} updateItem={props.updateItem} cartManager={props.cartManager} category={category} cartItems={cartItems} />
      
    </>
  );
}

export default Details;
