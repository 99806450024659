 
import React, { useEffect, useState } from "react";
import Featured from "./Featured";
import Subscribe from "./Subscribe";
import Vendor from "./Vendor";
import Carousel from "./Carousel";
import ProductList from "./ProductList";


function Content(props) {
  const [cartItems, setCartItems] = useState(props.cartItems);
  const [category, setCategory] = useState(props.category);
  const [area, setArea] = useState(props.area);
  const [item, setItem] = useState(props.item);
  if (props.item !== item) setArea(props.item);
  if (props.category !== category) setCategory(props.category);

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])  

  useEffect(() => {
    setCategory(props.category)
}, [props.category]);

  return (
    <>
      <Carousel area={props.area} fullName={props.fullName} userDetails={props.userDetails} handleClick={props.handleClick} />
      <Featured />
      <ProductList item={{}} updateItem={props.updateItem} cartManager={props.cartManager} category={category} cartItems={cartItems} />
      <Vendor />
      <Subscribe />
    </>
  );
}

export default Content;
