 
import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom'

function ShoppingCart(props) {
  const [cartItems, setCartItems] = useState(props.cartItems);
  const [subTotal, setSubTotal] = useState(0);
  const [category, setCategory] = useState(props.category);
  if (props.category !== category) setCategory(props.category);

  
  useEffect(() => {
    setCartItems(props.cartItems)
    getSubTotal(props.category)
}, [props.cartItems]);

  const getSubTotal = (prodCategories) => {
      let total = 0
      cartItems.map(row=>{
        let categoryData = prodCategories.filter(cat => cat.id == row.categoryid)
        let product = categoryData[0].product.filter(rw => rw.id == row.productid)[0];
       
        total += product.currentPrice * row.count
    })
    setSubTotal(total);
  }

  return (
    <div className="container-fluid pt-5">
        <div className="row px-xl-5">
            <div className="col-lg-8 table-responsive mb-5">
                <table className="table table-bordered text-center mb-0">
                    <thead className="bg-secondary text-dark">
                        <tr>
                            <th>Products</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Total</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody className="align-middle">
                        {cartItems[0] == undefined && <tr className="text-center"><td colSpan='5'>No Data</td></tr>}
                        {cartItems.map((row,index) => {
                            let categoryData = category.filter(cat => cat.id == row.categoryid)
                            let product = categoryData[0].product.filter(rw => rw.id == row.productid)[0];
                        return(<tr>
                            <td className="align-middle"><img src={product.imageUrl} alt="" style={{width: '50px'}} /> {product.name}</td>
                            <td className="align-middle">{'GHS ' + product.currentPrice}</td>
                            <td className="align-middle">
                                <div className="input-group quantity mx-auto" style={{width: '100px'}}>
                                    <div className="input-group-btn">
                                        <button className="btn btn-sm btn-primary btn-minus" onClick={() => props.quantityManager(0, row)} >
                                        <i className="fa fa-minus"></i>
                                        </button>
                                    </div>
                                    <input type="text" className="form-control form-control-sm bg-secondary text-center" value={row.count} />
                                    <div className="input-group-btn">
                                        <button className="btn btn-sm btn-primary btn-plus" onClick={() => props.quantityManager(1, row)}>
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </td>
                            <td className="align-middle">{'GHS ' + (product.currentPrice * row.count)}</td>
                            <td className="align-middle"><button className="btn btn-sm btn-primary" onClick={() => props.deleteItem(row)}><i className="fa fa-times"></i></button></td>
                        </tr>)})}
                        
                    </tbody>
                </table>
            </div>
            <div className="col-lg-4">
                <form className="mb-5" action="">
                    <div className="input-group">
                        <input type="text" className="form-control p-4" placeholder="Coupon Code" />
                        <div className="input-group-append">
                            <button className="btn btn-primary">Apply Coupon</button>
                        </div>
                    </div>
                </form>
                
                <div className="card border-secondary mb-5">
                    <div className="card-header bg-secondary border-0">
                        <h4 className="font-weight-semi-bold m-0">Cart Summary</h4>
                    </div>
                    <div className="card-body">
                        <div className="d-flex justify-content-between mb-3 pt-1">
                            <h6 className="font-weight-medium">Subtotal</h6>
                        <h6 className="font-weight-medium">GHS {subTotal}</h6>
                        </div>
                        <div className="d-flex justify-content-between">
                            <h6 className="font-weight-medium">Shipping</h6>
                            <h6 className="font-weight-medium">GHS 10</h6>
                        </div>
                    </div>
                    <div className="card-footer border-secondary bg-transparent">
                        <div className="d-flex justify-content-between mt-2">
                            <h5 className="font-weight-bold">Total</h5>
                            <h5 className="font-weight-bold">GHS {subTotal + 10}</h5>
                        </div>
                        {subTotal > 0 && <Link to="/checkout" className="btn btn-block btn-primary my-3 py-3">Proceed To Checkout</Link>}
                    </div>
                </div>
            </div>
        </div>
     </div>

  );
}

export default ShoppingCart;
