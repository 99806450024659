 
import React, { useEffect, useState} from "react";
import Navbar from "./Navbar";
import ProductDetailsHeader from "./ProductDetailsHeader";
import ProductsByCategory from "./ProductsByCategory";
import { useLocation } from 'react-router-dom'


function CategoryDetails(props) {
  const [productInfo, setProductInfo] = useState([]);
  const [area2, setArea] = useState(props.area);
  const [item, setItem] = useState(props.item);
  if (props.item !== item) setItem(props.item);
  if (props.area !== area2) setArea(props.area);
  const [category, setCategory] = useState(props.category);
  if (props.category !== category) setCategory(props.category);
  const location = useLocation()

  useEffect(() => {
    if (location.state != undefined){ 
      searchfilter(location.state.searchVal, props.category)
    }
    if(props.area != 0){
      searchfilter(props.area, props.category)
    }
}, [props.area]);

 React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const searchfilter = (searchText, categoryData) => {
    let data = []
    
    categoryData.map(row => {
      row.product.map((prod,index) =>{
        
        if(isNaN(searchText)){
        if(prod.name.toLowerCase().includes(searchText.toLowerCase())){
            data.push({categoryid: row.id, productid: prod.id, catname:row.name})
        }
       }else if(prod.categoryid == searchText){
         data.push({categoryid: row.id, productid: prod.id, catname:row.name})
       }
      })
    })
    
    if(data.length == 0){
      let cat = categoryData.filter(row => row.id == searchText)
      data.push({categoryid: cat[0].id, productid: 0, catname:cat[0].name});
    }
    setProductInfo(data);
}

  return (
    <>
      <Navbar area={0} area2={area2} fullName={props.fullName} userDetails={props.userDetails} handleClick={props.handleClick} />
      <ProductDetailsHeader heading={'Category Detail'} />
      <ProductsByCategory item={props.item} updateItem={props.updateItem} handleClick={props.handleClick} cartManager={props.cartManager} category={category} productInfo={productInfo} quantityManager={props.quantityManager} />
    </>
  );
}

export default CategoryDetails;
