 
import React, { useState } from "react";
import CategoryNav2 from "./CategoryNav2";
import MenuNav from "./MenuNav";

function Navbar(props) {

  const handleCardChange = (value, area) => {
     // setCards(prevState => ({ ...prevState, value }));
  }

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
  }

  return (
    <div className="container-fluid">
        <div className="row border-top px-xl-5">
            <CategoryNav2 area={props.area2} handleClick={props.handleClick} />
            <div className="col-lg-9">
            <MenuNav area={props.area} fullName={props.fullName} userDetails={props.userDetails} />
            </div>
        </div>
    </div>
  );
}

export default Navbar;
